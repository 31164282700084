import * as React from "react";

const IndexStatusPage = ({}: {}) => {
  const [results, setResults] = React.useState();

  React.useEffect(() => {
    const algoliasearch = require("algoliasearch");
    const client = algoliasearch(
      "ODQJXR6FON",
      "0e406faab78dc1d9c33a231cc3c27f2f"
    );
    const index = client.initIndex("dipra_source_index");
    let hits = [];
    // Get all records as an iterator
    index
      .browseObjects({
        batch: (batch) => {
          hits = hits.concat(batch);
        }
      })
      .then(() => {
        console.log("hits", hits);
        setResults(hits);
      });
  }, []);

  return (
    <>
      {results && results.length && <p>working</p>}
      {!results || (results.length < 1 && <p>down</p>)}
    </>
  );
};

export default IndexStatusPage;
